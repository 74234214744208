// extracted by mini-css-extract-plugin
export var header = "about-module--header--2HFjf header-module--banner--1Slbc";
export var section = "about-module--section--2Lxnk";
export var logos = "about-module--logos--bXtAW";
export var background = "about-module--background--2yHaJ";
export var founder = "about-module--founder--33J0U";
export var image_blob = "about-module--image_blob--MMem5";
export var underlay = "about-module--underlay--2jbBS";
export var graphic = "about-module--graphic--5kf9Q";
export var paw = "about-module--paw--3lmp4";
export var heart = "about-module--heart--3uZRy";
export var squiggle = "about-module--squiggle--3IXjI";
export var leaf = "about-module--leaf--3GYjd";
export var dots = "about-module--dots--13svi";
export var blob = "about-module--blob--2ml_7";
export var aimhigher = "about-module--aimhigher--285eg";